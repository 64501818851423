<template>
  <div>
    REZEPT
    {{ $route.params.recipeSlug }}
    {{ $route.params.recipeId }}
    <br/>
    {{ recipe }}
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";
import axios from "axios";

export default class RecipeDetail extends Vue {
  private recipe: any = {};

  public async created() {
    try {
      const response = await axios.get(
          process.env.VUE_APP_API_RECIPE_HOST +
          "/recipe/" +
          this.parseRecipeId(this.$route.params.recipeId as string)
      );
      this.recipe = response.data;
    } catch (e) {
      console.error(e);
    }
  }

  private parseRecipeId(recipeId: string) {
    console.log("recipeId: " + recipeId);
    return parseInt(("" + recipeId).replace(/[^\d.]/g, ""));
  }
}
</script>

<style lang="scss">
.card-recipe {
  height: 100%;
}

.card-recipe-category {
  position: relative;

  .category-text-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    align-content: center;
  }

  .category-text {
    width: 100%;
    font-size: 2.5em;
    text-transform: uppercase;
    color: #ffffff;
    text-align: center;
    text-shadow: 1px 1px 1px #444444;
  }
}
</style>
