<template>
  <div>
    KATEGORIE
    {{ $route.params.categorySlug }}
    {{ $route.params.categoryId }}
    {{ recipes }}

    <ul id="recipes">
      <li v-for="recipe in recipes" v-bind:key="recipe.id">
        {{ recipe.name }} WTF
      </li>
    </ul>

    <router-link
        :to="{
        name: 'recipe',
        params: { recipeSlug: 'cannelloni-ala-carmela', recipeId: 1 }
      }"
    >TEST
    </router-link>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";
import axios from "axios";

export default class RecipeCategoryDetail extends Vue {
  private recipes: any = [];

  public async created() {
    try {
      console.log(process.env.VUE_APP_API_RECIPE_HOST);
      console.log(process.env);
      const response = await axios.get(
          process.env.VUE_APP_API_RECIPE_HOST + "/recipe"
      );
      this.recipes = response.data;
    } catch (e) {
      console.error(e);
    }
  }
}
</script>

<style lang="scss">
.card-recipe {
  height: 100%;
}

.card-recipe-category {
  position: relative;

  .category-text-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    align-content: center;
  }

  .category-text {
    width: 100%;
    font-size: 2.5em;
    text-transform: uppercase;
    color: #ffffff;
    text-align: center;
    text-shadow: 1px 1px 1px #444444;
  }
}
</style>
