import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import RecipeCategoryList from "@/views/RecipeCategoryOverview.vue";
import RecipeCategoryDetail from "@/views/RecipeCategoryDetail.vue";
import RecipeDetail from "@/views/RecipeDetail.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/rezepte",
    name: "recipe-category-list",
    component: RecipeCategoryList
  },
  {
    path: "/rezepte/:categorySlug-:categoryId",
    name: "recipe-category",
    component: RecipeCategoryDetail
  },
  {
    path: "/rezept/:recipeSlug-:recipeId",
    name: "recipe",
    component: RecipeDetail
  }
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue")
  // }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
