
import { Vue } from "vue-class-component";

export default class RecipeCategoryOverview extends Vue {
  private categories: Array<any> = [];

  public created() {
    this.categories = [
      {
        id: 1,
        name: "Backen",
        image: require("@/assets/category-backen.jpg"),
        slug: "backen"
      },
      {
        id: 2,
        name: "Eintopf",
        image: require("@/assets/stock-gnocchi.jpg"),
        slug: "eintopf"
      },
      {
        id: 2,
        name: "Italienisch",
        image: require("@/assets/stock-gnocchi.jpg"),
        slug: "italienisch"
      }
    ]
  }
}
