<template>
  <div id="app">
    <div id="nav">
      <nav class="navbar" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
          <a class="navbar-item" href="https://bulma.io">
            <router-link to="/">
              <img src="@/assets/basteleki-small.svg" alt="basteleki"/>
            </router-link>
          </a>

          <a
              role="button"
              class="navbar-burger"
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div id="navbarBasicExample" class="navbar-menu">
          <div class="navbar-start">
            <router-link to="/rezepte" class="navbar-item">
              Rezepte
            </router-link>

            <router-link to="/" class="navbar-item">
              Basteleien
            </router-link>
          </div>

          <div class="navbar-end">
            <div class="navbar-item">
              <a href="https://www.instagram.com/basteleki" target="_blank">
                <img src="@/assets/instagram.png" alt="Instagram"/>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>

    <section class="section">
      <div class="container">
        <router-view/>
      </div>
    </section>

    <footer class="section footer">
      <div class="content container has-text-centered">
        <strong>Basteleki.de</strong> -
        <router-link to="/">Impressum</router-link>
        -
        <router-link to="/">Datenschutz</router-link>
      </div>
    </footer>
  </div>
</template>

<style lang="scss">
@import "./scss/variables";

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  .navbar {
    //background: #e8eee0;
  }

  .navbar-brand img {
    width: 160px;
    max-height: none;
    padding-bottom: 6px;
  }

  .navbar-item {
    align-items: flex-end;
  }
}
</style>
