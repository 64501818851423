
import { Vue } from "vue-class-component";
import axios from "axios";

export default class RecipeCategoryDetail extends Vue {
  private recipes: any = [];

  public async created() {
    try {
      console.log(process.env.VUE_APP_API_RECIPE_HOST);
      console.log(process.env);
      const response = await axios.get(
          process.env.VUE_APP_API_RECIPE_HOST + "/recipe"
      );
      this.recipes = response.data;
    } catch (e) {
      console.error(e);
    }
  }
}
