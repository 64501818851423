
import { Vue } from "vue-class-component";
import axios from "axios";

export default class RecipeDetail extends Vue {
  private recipe: any = {};

  public async created() {
    try {
      const response = await axios.get(
          process.env.VUE_APP_API_RECIPE_HOST +
          "/recipe/" +
          this.parseRecipeId(this.$route.params.recipeId as string)
      );
      this.recipe = response.data;
    } catch (e) {
      console.error(e);
    }
  }

  private parseRecipeId(recipeId: string) {
    console.log("recipeId: " + recipeId);
    return parseInt(("" + recipeId).replace(/[^\d.]/g, ""));
  }
}
