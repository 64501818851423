<template>
  <div class="columns is-desktop is-multiline">
    <div class="column is-3" v-for="category in categories" :key="category.id">
      <div class="card card-recipe-category">
        <router-link
            :to="{
            name: 'recipe-category',
            params: { categorySlug: category.slug, categoryId: category.id }
          }"
        >
          <div class="card-image">
            <figure class="image is-16by9 is-rounded">
              <img
                  :src="category.image"
                  :alt="category.name"
                  class="has-ratio"
              />
            </figure>
          </div>
          <div class="category-text-container">
            <div class="category-text">
              {{ category.name }}
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";

export default class RecipeCategoryOverview extends Vue {
  private categories: Array<any> = [];

  public created() {
    this.categories = [
      {
        id: 1,
        name: "Backen",
        image: require("@/assets/category-backen.jpg"),
        slug: "backen"
      },
      {
        id: 2,
        name: "Eintopf",
        image: require("@/assets/stock-gnocchi.jpg"),
        slug: "eintopf"
      },
      {
        id: 2,
        name: "Italienisch",
        image: require("@/assets/stock-gnocchi.jpg"),
        slug: "italienisch"
      }
    ]
  }
}
</script>

<style lang="scss">
.card-recipe {
  height: 100%;
}

.card-recipe-category {
  position: relative;

  .category-text-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    align-content: center;
  }

  .category-text {
    width: 100%;
    font-size: 2.5em;
    text-transform: uppercase;
    color: #ffffff;
    text-align: center;
    text-shadow: 1px 1px 1px #444444;
  }
}
</style>
